<script>
import Vue from 'vue';
import PincodeInput from 'vue-pincode-input';

import VueCountdown from '@chenfengyuan/vue-countdown';

import { ModalPlugin } from 'bootstrap-vue'
Vue.use(ModalPlugin)

import { LayoutPlugin } from 'bootstrap-vue'
Vue.use(LayoutPlugin)

import { FormPlugin } from 'bootstrap-vue'
Vue.use(FormPlugin)

import { ButtonPlugin } from 'bootstrap-vue'
Vue.use(ButtonPlugin)

export default {
    data() {
        return {
            isVisitorNameReq:0,
            contactNo:'',
             changedInputValue:'',
            isValNum:0,
            countryCode:'+91',
            email:'',
            contact:'',
            code:'',
             show: false,
             counting: false,
              variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark', 'white'],
        // headerBgVariant: 'dark',
        // headerTextVariant: 'light',
        // bodyBgVariant: 'light',
        // bodyTextVariant: 'dark',
        footerBgVariant: 'light',
        footerTextVariant: 'dark',
            isHidden: true,
            authError: '',
            isAuthError: false,
            regMobAuthError:'',
            regName:'',
            regEmail:'',
            regCorporateCode:'',
            regerror:'',
            hiddenOTPReg: true,
            codeReg: '',
            corporatesBranches: undefined,
            selectedBranchID:'',
            copBranchError:'',
            regSendOTPData: 0,
            regVerifyOTPData: 0,
            sendOTPData: 0,
            verifyOTPData: 0,
            regMobSendOTPData: 0,
            regMobVerifyOTPData: 0,
            userName:'',
            localStorageUserData: '',
            params: {
                    client_id: "855252858658-sihf66i67ut7cdul3hfm132b95r8agct.apps.googleusercontent.com"
                }, //AddAddressDemo
                // only needed if you want to render the button with the google ui
                renderParams: {
                    width: 250,
                    height: 50,
                    longtitle: true
                }
        }
    },

    components: {
        PincodeInput,
        VueCountdown,
    },

    methods:{
        inputValueCheck(event) {
						var inputString = event.target.value;
                        this.changedInputValue = inputString;
                        var x = inputString.charAt(0);
                        var number = !isNaN(parseFloat(x)) && !isNaN(x - 0);
                        if(number == true){
                            this.isValNum = 1;
                        }else{
                            this.isValNum = 0;
                        }
					},

    // Resend Button timer 
    startCountdown: function () {
      this.counting = true;
    },
    onCountdownEnd: function () {
      this.counting = false;
    },


    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn(e){
        //   let formData = new FormData();  
           /*
                Add the form data we need to submit
            */
            // formData.append('phoneNumber', this.changedInputValue);
            if(this.changedInputValue == "")
            {
                this.authError = 'Please fill all the required details';
            }
            if(this.changedInputValue !== ''){
                this.sendOTPData = 1; 
                this.authError = '';
                if (this.isValNum == 0) 
                {
                    var isMobile = -1;    
                }
                else if (this.isValNum == 1) 
                {
                    var isMobile = 1;
                }
                if(this.countryCode !== ''){
                    // var data = this.countryCode+this.changedInputValue;
                    var data = this.changedInputValue;
                }else{
                    var data = this.changedInputValue;
                }
                let jsonLogData = JSON.stringify({ contactData: data,
                                                    isMobile: isMobile
                                        });
                // formData.append('password', this.posts.password);

                e.preventDefault();
                // this.axios.post('http://devapi.creativegalileo.com:8080/littlesingham-1.0/api/auth', jsonLogData,{
                // headers: {
                // // Overwrite Axios's automatically set Content-Type
                // 'Content-Type': 'application/json'
                // }
                // })
                Vue.axios.post('/sendOTPLoginUser', jsonLogData)
                    .then((result)=>{
                        // console.log(result);
                        let status = result.status;
                         if(status==200 && result.data.status == 200){
                             this.startCountdown()
                             this.isHidden = false;
                             this.sendOTPData = 0; 
                            //  this.$router.push('/');
                         }
                         else{
                         this.isAuthError = true;
                        this.authError = result.data.message;
                        this.sendOTPData = 0;
                    }
                    //   console.warn(result);
                }).catch((error) => {
                        this.sendOTPData = 0;
                        console.log(error.response.data.message);
                        this.isAuthError = true;
                        // this.authError = error.response.data.message;
                        this.authError = "Something went wrong";
                    });
            }
      
    },
    verifyOTP(e){

            if(this.code == '')
            {
                 this.authError = 'Please enter Otp';
            }
            if(this.code !== ''){
                this.verifyOTPData = 1;
                if(this.countryCode !== ''){
                    // var data = this.countryCode+this.changedInputValue;
                    var data = this.changedInputValue;
                }else{
                    var data = this.changedInputValue;
                }
                let otpJson = JSON.stringify({ contactData: data,
                                            otp: this.code
                                            // deviceType: 1,
                                            // androidKey: '',
                                            // iOSKey: '',
                                            // appVersion: ''
                                            });
                // formData.append('password', this.posts.password);

                e.preventDefault();
                // this.axios.post('http://127.0.0.1:8000/api/loginWithOTP', otpJson,{
                // headers: {
                // // Overwrite Axios's automatically set Content-Type
                // 'Content-Type': 'application/json'
                // }
                // })
                this.axios.post('/loginWithOTP', otpJson)
                    .then((result)=>{
                        let status = result.status;
                        // console.log(result);
                         if(status==200 && result.data.status == 200){
                            // this.$session.start()
                            // this.$session.set('ck_user_token', result.data.user.token)
                            // this.$session.getAll();
                            // localStorage.setItem('token',result.data.user.token);
                            var userData = {
                                "utid":result.data.token,
                            }
                            localStorage.setItem('uTid',JSON.stringify(userData));
                            // console.log(localStorage.getItem('userData'));
                            //  this.startCountdown()
                            //  this.isHidden = false;
                            this.verifyOTPData = 0;
                             this.$bvModal.hide('modal-login');
                            if(result.data.user.isCorporateUser == 1 && (result.data.user.corporateBranchID == '' || result.data.user.corporateBranchID == 0)){
                                this.$bvModal.show("modal-corporateBranch");
                                this.geCorporateBranchByID();
                            }else{
                                //  this.$router.push('/cafeterias');
                                 if(this.checkOutPage == 1)
                                 {
                                    // this.$router.push('/checkOut');
                                    location.reload();
                                 }
                                 else
                                 {
                                     if(this.$route.name == 'home')
                                     {
                                        location.reload();
                                     }
                                     else
                                     {
                                        // this.$router.push('/');
                                        window.location.href = '/';
                                     }
                                 }
                            }
                         }
                         else{
                         this.isAuthError = true;
                        this.authError = result.data.message;
                        this.verifyOTPData = 0;
                    }
                    //   console.warn(result);
                }).catch((error) => {
                        this.verifyOTPData = 0;

                        console.log(error.response);
                        this.isAuthError = true;
                        // this.authError = error.response.data.message;
                        this.authError = "Something went wrong";
                    });
            }
      
    },
    tryToSignUp(e){
        
        if(this.regName == "" || this.regEmail == ""){
             e.preventDefault();
            this.regerror = "Please Fill all the required fields";
        }else{
            this.regSendOTPData = 1;
            this.regerror = "";
             let registerData = JSON.stringify({ userName: this.regName,
                                                emailID: this.regEmail,
                                                corporateCode: this.regCorporateCode
                                            });
             this.axios.post('/registerUser', registerData)
                    .then((result)=>{
                        let status = result.status;
                        // console.log(result);
                        
                         if(status==200 && result.data.status==200){
                            this.hiddenOTPReg = false;
                            this.regerror = "";
                            this.startCountdown();
                            this.regSendOTPData = 0;
                         }
                         else if(status == 208)
                         {
                            this.regSendOTPData = 0;
                             this.regerror = result.data.message;
                         }
                         else{
                            this.regSendOTPData = 0;
                         this.isAuthError = true;
                        // this.regerror = result.data.message;
                        this.regerror = "Something went wrong";
                    }
                    //   console.warn(result);
                });
        }
    },
    verifyOTPReg(e){
            if(this.codeReg !== ''){
                this.regVerifyOTPData = 0;
                
                let otpJson = JSON.stringify({ contactData: this.regEmail,
                                                otp: this.codeReg
                                            });
                // formData.append('password', this.posts.password);

                e.preventDefault();
                this.axios.post('/regWithOTP', otpJson)
                    .then((result)=>{
                        let status = result.status;
                        // console.log(result);
                            
                         if(status==200 && result.data.status==200){
                            this.hiddenOTPReg = true;
                          var userData = {
                                "utid":result.data.token,
                            }
                            localStorage.setItem('uTid',JSON.stringify(userData));
                            
                             this.regVerifyOTPData = 0;
                            this.$bvModal.hide('modal-register');
                            if(result.data.data.isCorporateUser == 1){
                                this.$bvModal.show("modal-corporateBranch");
                                this.geCorporateBranchByID();
                            }else{
                                //  this.$router.push('/cafeterias');
                                if(this.$route.name == 'home')
                                     {
                                        location.reload();
                                     }
                                     else
                                     {
                                        // this.$router.push('/');
                            			window.location.href = '/';
                                     }
                            }
                            
                         }
                         else if(result.data.status == 204)
                         {
                             this.regVerifyOTPData = 0;
                             this.regerror = result.data.message;
                         }
                         else{
                             this.regVerifyOTPData = 0;
                         this.isAuthError = true;
                      this.regerror = result.data.message;
                    }
                    //   console.warn(result);
                });
            }
      
    },
    geCorporateBranchByID(){
                // let CorpID = this.$userData.corporateID;
                // let getCorpBranches = JSON.stringify({ corporateID: CorpID
                //                             });
                // formData.append('password', this.posts.password);

                // e.preventDefault();
                // this.axios.post('/getAllCorporateBranchesByID', getCorpBranches)
                // this.bearerToken = '';
                if(JSON.parse(localStorage.getItem('uTid')) != null)
                {
                    this.bearerToken = JSON.parse(localStorage.getItem('uTid')).utid; 
                }
                // this.bearerToken = token;
                            // console.log(localStorage.getItem('uTid'));
                 
                const headers = {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + this.bearerToken,
                                };
                this.axios.get('/getAllCorporateBranchesByID',{headers})
                    .then((result)=>{
                        let status = result.status;
                        // console.log(result);
                            
                         if(status==200){
                             this.corporatesBranches = result.data.corporateBranches;
                            //  console.log(this.corporatesBranches);
                         }
                         else{
                            this.isAuthError = true;
                            this.regerror = result.data.message;
                        }
                });
      
    },
    corpBranchID(branchID)
    {
        this.selectedBranchID = branchID;
    },
    setCorpBranchForUser(e){
            if(this.selectedBranchID !== ''){
                // let emailID = this.$userData.emailID;
                 let updateCorpBrnachID = JSON.stringify({ corpBranchID: this.selectedBranchID,
                                                            // emailID: emailID
                                            });
                                            if(JSON.parse(localStorage.getItem('uTid')) != null)
                {
                    this.bearerToken = JSON.parse(localStorage.getItem('uTid')).utid; 
                }
                            // console.log(localStorage.getItem('uTid'));
                 
                const headers = {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer' + this.bearerToken,
                                };
             this.axios.post('/userCorpBranchID', updateCorpBrnachID, {headers})
                    .then((result)=>{
                        let status = result.status;
                        // console.log(result);
                        
                         if(status==200 && result.data.status==200){

                            // var userData = {
                            //     "utid":this.$userData.utid,
                            // }
                            // localStorage.setItem('uTid',JSON.stringify(userData));
                            // this.$router.push('/cafeterias');
                            // this.$router.push('/');
                                location.reload();
                         }
                         else if(result.data.status == 204)
                         {
                             this.copBranchError = result.data.message;
                         }
                         else{
                         this.isAuthError = true;
                        this.copBranchError = result.message;
                    }
                    //   console.warn(result);
                });
            }
        },
        
        tryToRegContact(e){
            if(this.contactNo == "")
            {
                this.regMobAuthError = 'Please fill all the required details';
            }
            if(this.contactNo !== ''){
                this.regMobSendOTPData = 1; 
                this.regMobAuthError = '';
                if(this.countryCode !== ''){
                    // var data = this.countryCode+this.changedInputValue;
                    var data = this.contactNo;
                    // var isMobile = 1;
                }
                if (this.$userData !== null) {
                    var userID = this.$userData.userID;
                }
                let jsonLogData = JSON.stringify({ userID: userID,
                                                    contactData: data,
                                        });
                e.preventDefault();
                Vue.axios.post('/sendOTPUser', jsonLogData)
                    .then((result)=>{
                        // console.log(result);
                        let status = result.status;
                         if(status==200 && result.data.status == 200){
                             this.startCountdown()
                             this.isHidden = false;
                             this.regMobSendOTPData = 0; 
                             this.changedInputValue = data; // to call verfyOTP Function
                             this.checkOutPage = 1;
                            //  this.$router.push('/');
                         }
                         else{
                        this.regMobAuthError = result.data.message;
                        this.regMobSendOTPData = 0;
                    }
                    //   console.warn(result);
                }).catch((error) => {
                        this.regMobSendOTPData = 0;
                        // this.authError = error.response.data.message;
                        this.regMobAuthError = "Something went wrong";
                    });
            }
      
        },
        tryToRegVisitor(e){
        
        if(this.contactNo == ""){
             e.preventDefault();
            this.regMobAuthError = "Please Fill all the required fields";
        }else{
            this.regMobSendOTPData = 1; 
            this.regMobAuthError = '';

             let registerData = JSON.stringify({ //visitorName: this.visitorRegName,
                                                    contactData: this.contactNo,
                                                    devicetype:1,
                                            });
             this.axios.post('/registerVisitor', registerData)
                    .then((result)=>{
                        let status = result.status;
                        // console.log(result);
                        
                         if(status==200 && result.data.status==200){
                            this.isHidden = false;
                            this.startCountdown();
                            this.regMobAuthError = "";
                            this.regMobSendOTPData = 0;
                            this.isVisitorNameReq =  result.data.isVisitorNameReq;
                         }
                         else if(status == 208)
                         {
                            this.regMobSendOTPData = 0;
                             this.regMobAuthError = result.data.message;
                         }
                         else{
                            this.regMobSendOTPData = 0;
                         this.isAuthError = true;
                        // this.regerror = result.data.message;
                        this.regMobAuthError = "Something went wrong";
                    }
                    //   console.warn(result);
                }).catch((error) => {
                        this.regMobSendOTPData = 0;
                        // this.authError = error.response.data.message;
                        this.regMobAuthError = "Something went wrong";
                    });
        }
    },
    verifyOTPRegVisitor(e){
        var visitorRegName = "";
        if(this.isVisitorNameReq == 1)
        {

            if(this.visitorRegName == "")
            {
                e.preventDefault();
                this.regMobAuthError = "Please Fill all the required fields";
            }
            visitorRegName = this.visitorRegName;
        }
            if(this.code !== ''){
                this.verifyOTPData = 1;
                
                let otpJson = JSON.stringify({ visitorName: visitorRegName,
                                                contactData: this.contactNo,
                                                otp: this.code
                                            });
                // formData.append('password', this.posts.password);

                e.preventDefault();
                this.axios.post('/regVisitorWithOTP', otpJson)
                    .then((result)=>{
                        let status = result.status;
                        // console.log(result);
                            
                         if(status==200 && result.data.status==200){
                            // this.hiddenOTPReg = true;
                          var userData = {
                                "utid":result.data.token,
                            }
                            localStorage.setItem('uTid',JSON.stringify(userData));
                            
                             this.verifyOTPData = 0;
                            this.$bvModal.hide('modal-visitor');
                            location.reload();
                            // if(result.data.data.isCorporateUser == 1){
                            //     this.$bvModal.show("modal-corporateBranch");
                            //     this.geCorporateBranchByID();
                            // }else{
                            //     //  this.$router.push('/cafeterias');
                            //     if(this.$route.name == 'home')
                            //          {
                            //             location.reload();
                            //          }
                            //          else
                            //          {
                            //             // this.$router.push('/');
                            // 			window.location.href = '/';
                            //          }
                            // }
                            
                         }
                         else if(result.data.status == 204)
                         {
                             this.regVerifyOTPData = 0;
                             this.regerror = result.data.message;
                         }
                         else{
                             this.regVerifyOTPData = 0;
                         this.isAuthError = true;
                      this.regerror = result.data.message;
                    }
                    //   console.warn(result);
                });
            }
      
    },
        onSuccess(googleUser) {
            // console.log(googleUser);
 
            // This only gets the user information: id, name, imageUrl and email
            // console.log(googleUser.getBasicProfile());
            var socialData = googleUser.getBasicProfile();
            var socialID = socialData.getId();
            var userName = socialData.getName();
            var userEmail = socialData.getEmail();
            //  console.log('ID: ' + socialData.getId()); // Do not send to your backend! Use an ID token instead.
            // console.log('Name: ' + socialData.getName());
            // console.log('Image URL: ' + socialData.getImageUrl());
            // console.log('Email: ' + socialData.getEmail())
            // console.log(socialData);
            let socialLogin = JSON.stringify({ 
                                            socialID:socialID,
                                            userName:userName,
                                            userEmail:userEmail,
                                            socialData: socialData,
											socialType: 1,
                                            devicetype:1,
												});

			Vue.axios.post('/socialLogin', socialLogin)
			.then((res)=>{
				let status = res.status;
				if(status == 200 && res.data.status==200)
				{
                    // console.log(res.data.data.userName);
                    var userData = {
                                "utid":res.data.token,
                    }
                    localStorage.setItem('uTid',JSON.stringify(userData));
                             this.$bvModal.hide('modal-login');
                            if(res.data.isCorporateUser == 1 && (res.data.corporateBranchID == '' || res.data.corporateBranchID == 0)){
                                this.$bvModal.show("modal-corporateBranch");
                                this.emailIDforReg = res.data.emailID;
                                this.geCorporateBranchByID();
                                // this.geCorporateBranchByID();
                            }else{
                                location.reload();
                            }
				}else{
					console.log(res.data.message)
				}
			});

     },
    }
}
</script>
<template>
    <div class="container-fuild m-0 p-0">
        <b-modal id="modal-register" :footer-bg-variant="footerBgVariant" hide-header style="max-width:440px!important;"  centered>
      <div class="closeModalDiv"><a  @click="$bvModal.hide('modal-register')"><img class="mainLogoImg" src="../../templateAssests/img/ckgallery/cancelBtn.png"  width="17" height="17" alt=""></a></div>
    <img class="mainLogoImg" src="../../templateAssests/img/cklogo.png" style="margin-top:14px!important;margin-bottom:24px;" width="132" height="60" alt="">
            <div  v-if="hiddenOTPReg" style="padding:0px 14px!important;">
                <h4 class="modalLoginText">Sign up</h4>
                <b-form @submit.prevent="tryToSignUp">

                        <div class="form-group emailInput">
                            <input class="form-control" v-model="regName" type="text" placeholder="Full Name *">
                            <!-- <i class="icon_mail_alt"></i> -->
                        </div>

                        <div class="form-group emailInput">
                            <input class="form-control" type="emil" v-model="regEmail" placeholder="Email *">
                            <span style="font-size:12px;">(Enter your official email, if you want to register as a Corporate user)</span>
                            <!-- <i class="icon_mail_alt"></i> -->
                        </div>

                        <div class="form-group emailInput">
                            <input class="form-control" type="text" v-model="regCorporateCode" placeholder="Corporate code (optional)">
                            <p class="errorDiv">{{regerror}}</p>
                            <!-- <i class="icon_mail_alt"></i> -->
                        </div>
                        <!-- <b-button type="submit" v-on:click="isHidden = !isHidden" @click="startCountdown" class="w-sm btn_1 otpBtn full-width">Send OTP</b-button> -->
                        <!-- <b-button type="submit" class="w-sm btn_1 otpBtn full-width">Sign up</b-button> -->
                        <b-button type="submit" v-if="regSendOTPData==0" class="w-sm btn_1 otpBtn full-width">Sign up</b-button>
                </b-form>
                <b-button type="submit" v-if="regSendOTPData==1" class="w-sm btn_1 otpBtn full-width"><img src="../../templateAssests/img/ckgallery/loader.gif" height="25" width="25"></b-button>
          
                <div class="divider"><span>OR</span></div>
                <div class="access_social">
                    <!-- :onFailure="onFailure" -->
                            <GoogleLogin class="full-width googleLoginBtn" :params="params" :onSuccess="onSuccess"><a href="#0" class="social_bt google anchor"><img src="../../templateAssests/img/Google__G__Logo.svg" alt="google" height="22" width="22"> Continue with Google</a></GoogleLogin>
					<!-- <a  v-b-modal.modal-corporateBranch class="social_bt google anchor"><img src="../../templateAssests/img/Google__G__Logo.svg" alt="google" height="22" width="22"> Continue with Google</a> -->
				</div>
                <p class="align-center" style="font-size:10px;">By continuing you agree to our</p>
                <ul id="signUpConditions" style="text-align:center;padding-left:0px;">
                    <li>Terms of Service</li>
                    <li>Privacy Policy</li>
                    <li>Content Policy</li>
                </ul>
                <!-- <div class="w-100 align-center signUptext">
                        <span>Don't have an account? <a href="/register">Sign up</a></span>
                    </div> -->
                 </div>
                <b-form @submit.prevent="verifyOTPReg">
                <div v-if="!hiddenOTPReg" class="otpSection align-center">
                    <h5 class="otpText">One Time Password is sent successfully</h5>
                    <p class="contactNumber">{{regEmail}} <a href="#" v-on:click="hiddenOTPReg = !hiddenOTPReg">Change</a></p>
                    <p class="enterOTP">Enter OTP</p>
                    <div class="input-wrapper align-center otpInputs">
                        <!-- <PincodeInput
                            v-model="codeReg"
                            length="6"
                        /> -->
                        <input
                        class="form-control"
                        v-model.number="codeReg"
                        type="number"
                        onKeyPress="if(this.value.length==6) return false;"
                        style="width: 60%; margin: auto; text-align: center;"
                        />
                    </div>
                    <p class="align-center errorDiv">{{regerror}}</p>
                    <p class="resendOTP align-right" :disabled="counting"><span class="timer"><vue-countdown v-if="counting" :time="30000" @end="onCountdownEnd" v-slot="{ totalSeconds }">{{ totalSeconds }}</vue-countdown></span> <span v-if="counting==true">Resend OTP</span> <a href="#" v-if="counting==false" class="customAnch" v-on:click="tryToSignUp">Resend OTP</a></p>
                        <!-- <b-button type="submit"  class="w-sm btn_1 verifyotpBtn">Verify OTP</b-button> -->
                        <b-button type="submit" v-if="regVerifyOTPData==0" class="w-sm btn_1 verifyotpBtn">Verify OTP</b-button>
                </div>
                </b-form>
                <div class="align-center" v-if="!hiddenOTPReg">
                <b-button type="submit" v-if="regVerifyOTPData==1" class="w-sm btn_1 verifyotpBtn"><img src="../../templateAssests/img/ckgallery/loader.gif" height="25" width="25"></b-button>
                </div>
                <template #modal-footer>
                     <div class="w-100 align-center signUptext">
                        <span>Already a Cloudkitch user? <a  @click="$bvModal.hide('modal-register')" v-b-modal.modal-login> Login here</a></span>
                    </div>
                </template>
  </b-modal>
<!-- Login Modal -->
  <b-modal id="modal-login" :footer-bg-variant="footerBgVariant" hide-header style="max-width:440px!important;"  centered>
      <div class="closeModalDiv"><a  @click="$bvModal.hide('modal-login')"><img class="mainLogoImg" src="../../templateAssests/img/ckgallery/cancelBtn.png"  width="17" height="17" alt=""></a></div>
          <img class="mainLogoImg" src="../../templateAssests/img/cklogo.png" style="margin-top:14px!important;margin-bottom:24px;" width="132" height="60" alt="">
            <div  v-if="isHidden" style="padding:0px 14px!important;">
                <h4 class="modalLoginText">Login</h4>
                <b-form @submit.prevent="tryToLogIn">

                        <div class="form-group emailInput">
                            <input class="form-control" type="email" v-if="isValNum === 0" v-on:keyup="inputValueCheck" :value="changedInputValue"  placeholder="Enter Phone number or Email id">
                             <div class="input-group" v-if="isValNum === 1">
                                <select class="input-group-text" style="background:transparent;" v-model="countryCode">
                                    <option value="+91" selected>+91</option>
                                    <option value="+44">+44</option>
                                    </select>
                                <input
                                type="number"
                                class="form-control"
                                id="specificSizeInputGroupUsername"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                maxlength="10"
                                autofocus
                                placeholder="Phone Number" v-on:keyup="inputValueCheck" :value="changedInputValue"
                                />
                            </div>
                            <!-- <i class="icon_mail_alt"></i> -->
                            <p class="errorDiv">{{authError}}</p>
                        </div>
                        <!-- <b-button type="submit" v-on:click="isHidden = !isHidden" @click="startCountdown" class="w-sm btn_1 otpBtn full-width">Send OTP</b-button> -->
                        <b-button type="submit" v-if="sendOTPData==0" class="w-sm btn_1 otpBtn full-width">Send OTP</b-button>
                </b-form>
                <b-button type="submit" v-if="sendOTPData==1" class="w-sm btn_1 otpBtn full-width"><img src="../../templateAssests/img/ckgallery/loader.gif" height="25" width="25"></b-button>
          
                <div class="divider"><span>OR</span></div>
                <div class="access_social">
                    <!-- :onFailure="onFailure" -->
                            <GoogleLogin class="full-width googleLoginBtn" :params="params" :onSuccess="onSuccess" ><a href="#0" class="social_bt google anchor"><img src="../../templateAssests/img/Google__G__Logo.svg" alt="google" height="22" width="22"> Continue with Google</a></GoogleLogin>
					<!-- <a href="#0" class="social_bt google anchor"><img src="../../templateAssests/img/Google__G__Logo.svg" alt="google" height="22" width="22"> Continue with Google</a> -->
				</div>
                <!-- <div class="w-100 align-center signUptext">
                        <span>Don't have an account? <a href="/register">Sign up</a></span>
                    </div> -->
                 </div>
                <b-form @submit.prevent="verifyOTP">
                <div v-if="!isHidden" class="otpSection align-center">
                    <h5 class="otpText">One Time Password is sent successfully</h5>
                    <p class="contactNumber" v-if="isValNum === 0">{{changedInputValue}} <a href="#" v-on:click="isHidden = !isHidden">Change</a></p>
                    <p class="contactNumber" v-if="isValNum === 1">{{ countryCode }} {{changedInputValue}} <a href="#" v-on:click="isHidden = !isHidden">Change</a></p>
                    <p class="enterOTP">Enter OTP</p>
                    <div class="input-wrapper align-center otpInputs">
                        <!-- <PincodeInput
                            v-model="code"
                            length="6"
                        /> -->
                        <input
                        class="form-control"
                        v-model.number="code"
                        type="number"
                        onKeyPress="if(this.value.length==6) return false;"
                        style="width: 60%; margin: auto; text-align: center;"
                        />
                    </div>
                    <p class="align-center errorDiv">{{authError}}</p>
                    <p class="resendOTP align-right" :disabled="counting"><span class="timer"><vue-countdown v-if="counting" :time="30000" @end="onCountdownEnd" v-slot="{ totalSeconds }">{{ totalSeconds }}</vue-countdown></span> <span v-if="counting==true">Resend OTP</span> <a href="#" class="customAnch" v-if="counting==false" v-on:click="tryToLogIn">Resend OTP</a></p>
                        <!-- <b-button type="submit"  class="w-sm btn_1 verifyotpBtn">Verify OTP</b-button> -->
                        <b-button type="submit" v-if="verifyOTPData==0" class="w-sm btn_1 verifyotpBtn">Verify OTP</b-button>
                </div>
                </b-form>
                <div class="align-center">
                <b-button type="submit" v-if="verifyOTPData==1" class="w-sm btn_1 verifyotpBtn"><img src="../../templateAssests/img/ckgallery/loader.gif" height="25" width="25"></b-button>
                </div>
                <template #modal-footer>
                     <div class="w-100 align-center signUptext">
                        <span>Don't have an account? <a @click="$bvModal.hide('modal-login')" v-b-modal.modal-register>Sign up</a></span>
                    </div>
                </template>
  </b-modal> 
<!-- / Login Modal -->

<!-- Mobile Modal -->
  <b-modal id="modal-mobile" hide-footer hide-header style="max-width:440px!important;"  centered>
      <div class="closeModalDiv"><a  @click="$bvModal.hide('modal-mobile')"><img class="mainLogoImg" src="../../templateAssests/img/ckgallery/cancelBtn.png"  width="17" height="17" alt=""></a></div>
          <img class="mainLogoImg" src="../../templateAssests/img/cklogo.png" style="margin-top:14px!important;margin-bottom:24px;" width="132" height="60" alt="">
            <div  v-if="isHidden" style="padding:0px 14px!important;">
                <!-- <h4 class="modalLoginText">Login</h4> -->
                <b-form @submit.prevent="tryToRegContact">

                        <div class="form-group emailInput">
                             <div class="input-group">
                                <select class="input-group-text" style="background:transparent;" v-model="countryCode">
                                    <option value="+91" selected>+91</option>
                                    <option value="+44">+44</option>
                                    </select>
                                <input
                                type="number"
                                class="form-control"
                                id="specificSizeInputGroupUsername"
                                placeholder="Phone Number"
                                v-model="contactNo"
                                />
                            </div>
                            <!-- <i class="icon_mail_alt"></i> -->
                            <p class="errorDiv">{{regMobAuthError}}</p>
                        </div>
                        <!-- <b-button type="submit" v-on:click="isHidden = !isHidden" @click="startCountdown" class="w-sm btn_1 otpBtn full-width">Send OTP</b-button> -->
                        <b-button type="submit" v-if="regMobSendOTPData==0" class="w-sm btn_1 otpBtn full-width">Send OTP</b-button>
                </b-form>
                <b-button type="submit" v-if="regMobSendOTPData==1" class="w-sm btn_1 otpBtn full-width"><img src="../../templateAssests/img/ckgallery/loader.gif" height="25" width="25"></b-button>
          
                <!-- <div class="w-100 align-center signUptext">
                        <span>Don't have an account? <a href="/register">Sign up</a></span>
                    </div> -->
                 </div>
                <b-form @submit.prevent="verifyOTP">
                <div v-if="!isHidden" class="otpSection align-center">
                    <h5 class="otpText">One Time Password is sent successfully</h5>
                    <p class="contactNumber" v-if="isValNum === 0">{{changedInputValue}} <a href="#" v-on:click="isHidden = !isHidden">Change</a></p>
                    <p class="contactNumber" v-if="isValNum === 1">{{ countryCode }} {{changedInputValue}} <a href="#" v-on:click="isHidden = !isHidden">Change</a></p>
                    <p class="enterOTP">Enter OTP</p>
                    <div class="input-wrapper align-center otpInputs">
                        <!-- <PincodeInput
                            v-model="code"
                            length="6"
                        /> -->
                        <input
                        class="form-control"
                        v-model.number="code"
                        type="number"
                        onKeyPress="if(this.value.length==6) return false;"
                        style="width: 60%; margin: auto; text-align: center;"
                        />
                    </div>
                    <p class="align-center errorDiv">{{authError}}</p>
                    <p class="resendOTP align-right" :disabled="counting"><span class="timer"><vue-countdown v-if="counting" :time="30000" @end="onCountdownEnd" v-slot="{ totalSeconds }">{{ totalSeconds }}</vue-countdown></span> <span v-if="counting==true">Resend OTP</span> <a href="#" class="customAnch" v-if="counting==false" v-on:click="tryToLogIn">Resend OTP</a></p>
                        <!-- <b-button type="submit"  class="w-sm btn_1 verifyotpBtn">Verify OTP</b-button> -->
                        <b-button type="submit" v-if="verifyOTPData==0" class="w-sm btn_1 verifyotpBtn">Verify OTP</b-button>
                </div>
                </b-form>
                <div class="align-center">
                <b-button type="submit" v-if="verifyOTPData==1" class="w-sm btn_1 verifyotpBtn"><img src="../../templateAssests/img/ckgallery/loader.gif" height="25" width="25"></b-button>
                </div>
  </b-modal> 
<!-- / Mobile Modal -->

<!-- Visitor Modal -->
  <b-modal id="modal-visitor" hide-footer hide-header style="max-width:440px!important;"  centered>
      <div class="closeModalDiv"><a  @click="$bvModal.hide('modal-visitor')"><img class="mainLogoImg" src="../../templateAssests/img/ckgallery/cancelBtn.png"  width="17" height="17" alt=""></a></div>
          <img class="mainLogoImg" src="../../templateAssests/img/cklogo.png" style="margin-top:14px!important;margin-bottom:24px;" width="132" height="60" alt="">
            <div  v-if="isHidden" style="padding:0px 14px!important;">
                <!-- <h4 class="modalLoginText">Login</h4> -->
                <b-form @submit.prevent="tryToRegVisitor">

                        <div class="form-group emailInput">
                             <div class="input-group">
                                <select class="input-group-text" style="background:transparent;" v-model="countryCode">
                                    <option value="+91" selected>+91</option>
                                    <option value="+44">+44</option>
                                    </select>
                                <input
                                type="number"
                                class="form-control"
                                id="specificSizeInputGroupUsername"
                                placeholder="Phone Number"
                                v-model="contactNo"
                                />
                            </div>
                            <!-- <i class="icon_mail_alt"></i> -->
                            <p class="errorDiv">{{regMobAuthError}}</p>
                        </div>
                        <!-- <b-button type="submit" v-on:click="isHidden = !isHidden" @click="startCountdown" class="w-sm btn_1 otpBtn full-width">Send OTP</b-button> -->
                        <b-button type="submit" v-if="regMobSendOTPData==0" class="w-sm btn_1 otpBtn full-width">Send OTP</b-button>
                </b-form>
                <b-button type="submit" v-if="regMobSendOTPData==1" class="w-sm btn_1 otpBtn full-width"><img src="../../templateAssests/img/ckgallery/loader.gif" height="25" width="25"></b-button>
          
                <!-- <div class="w-100 align-center signUptext">
                        <span>Don't have an account? <a href="/register">Sign up</a></span>
                    </div> -->
                 </div>
                <b-form @submit.prevent="verifyOTPRegVisitor">
                <div v-if="!isHidden" class="otpSection align-center">
                    <h5 class="otpText">One Time Password is sent successfully</h5>
                    <!-- <p class="contactNumber" v-if="isValNum === 0">{{changedInputValue}} <a href="#" v-on:click="isHidden = !isHidden">Change</a></p> -->
                    <p class="contactNumber">{{ countryCode }} {{contactNo}} <a href="#" v-on:click="isHidden = !isHidden">Change</a></p>

                    <div class="form-group emailInput" v-if="isVisitorNameReq==1">
                            <input class="form-control" v-model="visitorRegName" type="text" placeholder="Full Name *">
                            <!-- <i class="icon_mail_alt"></i> -->
                        </div>
                            <p class="errorDiv">{{regMobAuthError}}</p>

                    <p class="enterOTP">Enter OTP</p>
                    <div class="input-wrapper align-center otpInputs">
                        <!-- <PincodeInput
                            v-model="code"
                            length="6"
                        /> -->
                        <input
                        class="form-control"
                        v-model.number="code"
                        type="number"
                        onKeyPress="if(this.value.length==6) return false;"
                        style="width: 60%; margin: auto; text-align: center;"
                        />
                    </div>
                    <p class="align-center errorDiv">{{authError}}</p>
                    <p class="resendOTP align-right" :disabled="counting"><span class="timer"><vue-countdown v-if="counting" :time="30000" @end="onCountdownEnd" v-slot="{ totalSeconds }">{{ totalSeconds }}</vue-countdown></span> <span v-if="counting==true">Resend OTP</span> <a href="#" class="customAnch" v-if="counting==false" v-on:click="tryToRegVisitor">Resend OTP</a></p>
                        <!-- <b-button type="submit"  class="w-sm btn_1 verifyotpBtn">Verify OTP</b-button> -->
                        <b-button type="submit" v-if="verifyOTPData==0" class="w-sm btn_1 verifyotpBtn">Verify OTP</b-button>
                </div>
                </b-form>
                <div class="align-center">
                <b-button type="submit" v-if="verifyOTPData==1" class="w-sm btn_1 verifyotpBtn"><img src="../../templateAssests/img/ckgallery/loader.gif" height="25" width="25"></b-button>
                </div>
  </b-modal> 
<!-- / Visitor Modal -->

<!-- Corporate Branch modal -->
<b-modal id="modal-corporateBranch" scrollable hide-footer hide-header style="max-width:440px!important;"  centered>
      <div class="closeModalDiv"><a  @click="$bvModal.hide('modal-corporateBranch')"><img class="mainLogoImg" src="../../templateAssests/img/ckgallery/cancelBtn.png"  width="17" height="17" alt=""></a></div>
            <div style="padding:0px 14px!important;">
                <h4 class="modalLoginText" style="margin: 25px 0px 14px 0px!important;">Select your branch</h4>
                    <b-form @submit.prevent="setCorpBranchForUser">

                            <div class="form-group">
                                <div class="inner-addon right-addon">
                                    <i class="bx bx-search bxSearchModal"></i>
                                    <input type="text" class="form-control" placeholder="Search..."/>
                                </div>
                            </div>
                            <!-- <b-button type="submit" v-on:click="isHidden = !isHidden" @click="startCountdown" class="w-sm btn_1 otpBtn full-width">Send OTP</b-button> -->
                            <div v-if="corporatesBranches != undefined">
                                <div  v-for="corporates in corporatesBranches" :key="corporates.corpBranchID">
                                    <div class="row" v-on:click="corpBranchID(corporates.corpBranchID)">
                                        <div class="col-md-10 col-lg-10 col-xl-10 corporatebranchName">
                                        <p>  {{corporates.corporateName}} {{corporates.branchName}}</p>
                                        </div>
                                        <div class="col-md-2 col-lg-2 col-xl-2" v-if="corporates.corpBranchID == selectedBranchID">
                                            <img class="mainLogoImg" src="../../templateAssests/img/ckgallery/tickbranch.png" width="14" height="14" alt="">
                                        </div>
                                        <div class="corporateAddress">
                                            <p>{{corporates.address}}</p>
                                        </div>
                                        <hr class="hrBorder">
                                    </div>
                                </div>
                            </div>
                            <p class="align-center errorDiv">{{authError}}</p>
                            <b-button type="submit" class="w-sm btn_1 otpBtn full-width" style="margin-bottom:10px!important;">Submit</b-button>
                    </b-form>
                 </div>
  </b-modal> 
<!-- / Corporate Branch Modal -->
    </div>
</template>

<!-- SPECIFIC CSS -->
<style scoped src="../../templateAssests/css/home.css"></style>
<style scoped>
.contact{
        font-size:14px;
        color:#fff;
    }
    .contactAnch{
        color:#fff;
    }
    .contactSticky{
      color:#000!important;
      /* margin-top:15px; */
    }
    .contactSticky .contactAnch{
      color:#000;
    }
    .anchor:hover, .anchor:focus{
        color:#e54750;
    }
    .a.btn_1:hover, .btn_1:hover{
        color:#fff;
    }
    .userNameSticky{
        color:#000!important;
    }
    .main-cards{
        position:relative;
        top:-145px;
        z-index:100;
    }
    .cards{
        max-width:222px;
        max-height:245.43px;
        border-radius:5px;
    }
    .cardsRow{
        justify-content:center;
    }
    .opacity-mask{
        background-color:rgba(0, 0, 0, 0.6);
    }
    .ckLogo {
          bottom: 43px;
           position: relative;
           object-fit: cover;
           height: 100px;
    }
    #logo{
        padding-top:14px;
    }
    .mainLogoImg{
        width: 100%;
        object-fit: contain;
    }
    .mainLogoShow{
        display: block!important;
        margin-top: 10px;
    }
    .mainLogoHide{
        display: none;
    }
    .modal-body{
        padding:30px!important;
    }
    .modalLoginText{
        font-size:24px;
        margin-bottom: 14px;
        color:#212121;
    }
    .emailInput{
        margin-bottom: px;
    }
    .otpBtn{
        height: 45px;
        background: #FFc60c;
        border: none;
        color: #212121;
        margin-bottom: 37px;
    }
    .divider{
        color:#eaeaea;
        margin-bottom: 29px;
    }
    .google img{
        margin-right: 28px;
    }
    .google{
        border: 1px solid #EAEAEA;
        box-sizing: border-box;
        border-radius: 5px;
        color:#212121;
        font-size: 14px;
    }
    a.social_bt.google:hover, a.social_bt.google:focus{
        border: 1px solid #EAEAEA;
    }
    .signUptext{
        color: #212121;
        font-size: 14px;
    }
    .signUptext span a{
        color: #F3766A!important;
    }
    .modal-footer{
        height:50px!important;
    }
    .otpSection{
        margin-top: 40px;
    }
    .otpText{
        margin-bottom: 10px;
        color: #212121;
        font-size:14px;
    }
    .contactNumber{
        font-size:16px;
        color:#212121;
        margin-bottom: 17px;
    }
    .contactNumber a{
         color: #F3766A!important;
         font-size:13px;
    }
    .enterOTP{
        color:#777777!important;
        font-size:14px;
        margin-bottom:14px;
    }
    .otpInputs{
        margin-bottom: 16px;
    }
    .resendOTP{
        color: #777;
        margin-bottom: 15px;
         width:80%;
    }
    .resendOTP .timer{
        color: #F3766A;
       
    }
    .verifyotpBtn{
        width: 170px;
        height: 45px;
        background: #FFC60C;
        border-radius: 5px;
        border:none;
        margin-bottom: 19px;
    }
    #signUpConditions li{
        display:inline;
        margin-right: 10px;
        font-size: 10px;
        text-decoration: underline;
    }
    header{
        z-index: 101!important;
    }
    .closeModalDiv{
        float: right;
        padding-top: 6px;
        padding-right: 9px;
        cursor: pointer;
    }
    .inner-addon {
        position: relative;
    }
    .bxSearchModal{
        position: absolute;
        padding: 10px;
        pointer-events: none;
    }
    .errorDiv{
        font-size:14px;
        color: red;
    }
    .right-addon .bxSearchModal { right: 8px;font-size:22px;}
    .right-addon input { padding-right: 30px; border-radius: 20px;}
    
    .googleLoginBtn
    {
        width: 100%;
        background: transparent;
        border:none;
    }
    /* @media screen and (max-width: 767px) {
           .conatiner{
             padding-left:0;
             padding-right:0;
           }
    } */
    </style>