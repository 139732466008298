<script>
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
  VsaIcon,
} from "vue-simple-accordion";
import "vue-simple-accordion/dist/vue-simple-accordion.css";
export default {
  components: {
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    VsaIcon,
  },
  // data() {
  //   return {
  // 		showLogo: 1,
  //   }
  // },
  // mounted()
  // {
  //     if(this.$route.name && (this.$route.name == 'foodServiceHome' || this.$route.name == 'aboutUs' || this.$route.name == 'corporate'))
  // 	{
  // 		this.showLogo = 0;
  // 	}
  // },
};
</script>
<template>
  <footer class="noMobile">
    <!-- <div class="wave footer"></div> -->
    <div class="container margin_60_40 fix_mobile">
      <div class="row">
        <!-- <div class="col-lg-2 col-md-6">
                    <h3 data-target="#collapse_1">For Businesses</h3>
                    <div class="collapse dont-collapse-sm links" id="collapse_1">
                        <ul>
                            <li><a href="about.html">Cafeteria Alternatives</a></li>
                            <li><a href="submit-restaurant.html">Office Lunch Catering</a></li>
                            <li><a href="help.html">Meeting Catering</a></li>
                            <li><a href="account.html">Event Catering</a></li>
                            <li><a href="blog.html">Group Ordering</a></li>
                        </ul>
                    </div>
                </div> -->
        <div class="col-lg-2 col-md-6 noMobile darkBrown">
          <h3 data-target="#collapse_2">For Corporates & Enterprises</h3>
          <div class="collapse dont-collapse-sm links" id="collapse_2">
            <ul>
              <li><a href="/corporate">Digital Cafeteria</a></li>
              <li><a href="/corporate">Food court</a></li>
              <li><a href="/corporate">Events & Meetings</a></li>
              <li><a href="/corporate">Pop ups</a></li>
              <li><a href="/corporate">Enquiry</a></li>
            </ul>
          </div>
        </div>

        <div class="col-lg-2 col-md-6 noMobile darkBrown">
          <h3 data-target="#collapse_2">For Food Service Companies</h3>
          <div class="collapse dont-collapse-sm links" id="collapse_2">
            <ul>
              <li><a href="/foodServiceHome">Food Service Companies</a></li>
              <li><a href="/foodServiceHome">Restaurants & QSR</a></li>
              <li><a href="/foodServiceHome">Central Kitchens</a></li>
              <li><a href="/foodServiceHome">Cloud Kitchens</a></li>
              <li><a href="/foodServiceHome">Empanel with us</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 noMobile darkBrown">
          <h3 data-target="#collapse_3">Company</h3>
          <div class="collapse dont-collapse-sm links" id="collapse_3">
            <ul>
              <!-- <li>About us</li>
                            <li>Contact us</li> -->
              <li><a href="/aboutUs">About Us</a></li>
              <li><a href="tel:+917710999666">+91 771 099 9666</a></li>
              <li>
                <a href="mailto:enquiry@cloudkitch.co.in"
                  >enquiry@cloudkitch.co.in</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 noMobile darkBrown">
          <h3 data-target="#collapse_3">Legal</h3>
          <div class="collapse dont-collapse-sm links" id="collapse_4">
            <ul>
              <!-- <li><a href="/policies/terms-and-condition">Terms & Conditions</a></li> -->
              <li><a href="/policies/terms-of-use">Terms & Conditions</a></li>
              <li><a href="/policies/privacy-policy">Privacy Policy</a></li>
              <!-- <li><a href="/policies/grievance-policy">Grievance Policy</a></li> -->
              <!-- <li><a href="/policies/cookie-policy">Cookie Policy</a></li> -->
              <li>
                <a href="/policies/cancellation-refund"
                  >Cancellations & Refunds</a
                >
              </li>
            </ul>
          </div>
        </div>
        <vsa-list class="col-lg-2 col-md-6 onlyMobile">
          <vsa-item>
            <vsa-heading>
              For Corporates & Enterprises
            </vsa-heading>

            <vsa-content>
              <div class="links" id="collapse_2">
                <ul>
                  <li><a href="/corporate">Digital Cafeteria</a></li>
                  <li><a href="/corporate">Food court</a></li>
                  <li><a href="/corporate">Events & Meetings</a></li>
                  <li><a href="/corporate">Pop ups</a></li>
                  <li><a href="/corporate">Enquiry</a></li>
                </ul>
              </div>
              <!-- <a href="/foodServiceHome">Services</a> -->
            </vsa-content>
          </vsa-item>
        </vsa-list>
        <vsa-list class="col-lg-2 col-md-6 onlyMobile">
          <vsa-item>
            <vsa-heading>
              For Food Service Companies
            </vsa-heading>

            <vsa-content>
              <div class="links" id="collapse_2">
                <ul>
                  <li><a href="/foodServiceHome">Food Service Companies</a></li>
                  <li><a href="/foodServiceHome">Restaurants & QSR</a></li>
                  <li><a href="/foodServiceHome">Central Kitchens</a></li>
                  <li><a href="/foodServiceHome">Cloud Kitchens</a></li>
                  <li><a href="/foodServiceHome">Empanel with us</a></li>
                </ul>
              </div>
              <!-- <a href="/foodServiceHome">Services</a> -->
            </vsa-content>
          </vsa-item>
        </vsa-list>
        <vsa-list class="col-lg-2 col-md-6 onlyMobile">
          <vsa-item>
            <vsa-heading>
              Company
            </vsa-heading>

            <vsa-content>
              <div class="links" id="collapse_2">
                <ul>
                  <li><a href="/aboutUs">About Us</a></li>
                  <li><a href="tel:+917710999666">+91 771 099 9666</a></li>
                  <li>
                    <a href="mailto:enquiry@cloudkitch.co.in"
                      >enquiry@cloudkitch.co.in</a
                    >
                  </li>
                </ul>
              </div>
              <!-- <a href="/foodServiceHome">Services</a> -->
            </vsa-content>
          </vsa-item>
        </vsa-list>
        <vsa-list class="col-lg-2 col-md-6 onlyMobile">
          <vsa-item>
            <vsa-heading>
              Legal
            </vsa-heading>

            <vsa-content>
              <div class="links" id="collapse_2">
                <ul>
                  <!-- <li><a href="/policies/terms-and-condition">Terms & Conditions</a></li> -->
                  <li>
                    <a href="/policies/terms-of-use">Terms & Conditions</a>
                  </li>
                  <li><a href="/policies/privacy-policy">Privacy Policy</a></li>
                  <!-- <li><a href="/policies/grievance-policy">Grievance Policy</a></li> -->
                  <!-- <li><a href="/policies/cookie-policy">Cookie Policy</a></li> -->
                  <li>
                    <a href="/policies/cancellation-refund"
                      >Cancellations & Refunds</a
                    >
                  </li>
                </ul>
              </div>
              <!-- <a href="/foodServiceHome">Services</a> -->
            </vsa-content>
          </vsa-item>
        </vsa-list>
        <!-- <div class="col-lg-2 col-md-6"></div> -->
        <!-- <div class="col-lg-2 col-md-6"></div> -->

        <div class="col-lg-4 col-md-12 align-center">
          <!-- <h4><img :style="showLogo == 0 ? 'display:none;' : ''" src="../templateAssests/img/cklogo.png" height="80" alt="" class="footerLogo lazy"></h4> -->
          <div class="aboutCk">
            <p>
              Great food experience is about thoughtful preparation. CloudKitch
              makes that happen for you.
            </p>
          </div>
          <div class="appStores">
            <!-- <div class="col-6"> -->
            <a
              href="https://play.google.com/store/apps/details?id=com.ct.cloudkitch&hl=en_IN&gl=US"
              ><img
                src="../templateAssests/img/ckgallery/playStore.png"
                height="40"
                alt=""
                class="playStore lazy"
            /></a>
            <!-- </div> -->
            <!-- <div class="col-6"> -->
            <a
              href="https://apps.apple.com/cz/app/cloudkitch-virtual-food-court/id1541468758"
              ><img
                src="../templateAssests/img/ckgallery/appStore.png"
                height="40"
                alt=""
                class="appStore lazy"
            /></a>
            <!-- </div> -->
          </div>
          <ul class="footer-selector clearfix footer-social-links">
            <li>
              <a
                href="https://www.facebook.com/officialcloudkitch/"
                target="_blank"
                class="social-list-item"
                ><i class="fab fa-facebook-f facebook fabFooterIcons"></i
              ></a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/cloudkitch/"
                target="_blank"
                class="social-list-item"
                ><i class="fab fa-instagram instagram fabFooterIcons"></i
              ></a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/cloudkitch-private-limited"
                target="_blank"
                class="social-list-item"
                ><i class="fab fa-linkedin linkedIn fabFooterIcons"></i
              ></a>
            </li>
          </ul>
          <div class="rightsReserved darkBrown">
            <span class="rights"
              >© {{ new Date().getFullYear() }} CloudKitch Pvt Ltd. All Rights Reserved.</span
            >
          </div>
          <!-- <h3 data-target="#collapse_4">Keep in touch</h3>
                    <div class="collapse dont-collapse-sm" id="collapse_4">
                        <div id="newsletter">
                            <div id="message-newsletter"></div>
                            <form method="post" action="assets/newsletter.php" name="newsletter_form" id="newsletter_form">
                                <div class="form-group">
                                    <input type="email" name="email_newsletter" id="email_newsletter" class="form-control" placeholder="Your email">
                                    <button type="submit" id="submit-newsletter"><i class="arrow_carrot-right"></i></button>
                                </div>
                            </form>
                        </div>
                        <div class="follow_us">
                            <h5>Follow Us</h5>
                            <ul>
                                <li><a href="#0"><img src="../templateAssests/img/twitter_icon.svg" alt="" class="lazy"></a></li>
                                <li><a href="#0"><img src="../templateAssests/img/facebook_icon.svg" alt="" class="lazy"></a></li>
                                <li><a href="#0"><img src="../templateAssests/img/instagram_icon.svg" alt="" class="lazy"></a></li>
                                <li><a href="#0"><img src="../templateAssests/img/youtube_icon.svg" alt="" class="lazy"></a></li>
                            </ul>
                        </div>
                    </div> -->
        </div>
      </div>
      <!-- <hr> -->
      <!-- <div class="row bottomFooter">
                <div class="col-lg-6">
                    <ul class="footer-selector clearfix"> -->
      <!-- <li>
                            <div class="styled-select lang-selector">
                                <select>
                                    <option value="English" selected>English</option>
                                    <option value="French">French</option>
                                    <option value="Spanish">Spanish</option>
                                    <option value="Russian">Russian</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <div class="styled-select currency-selector">
                                <select>
                                    <option value="US Dollars" selected>US Dollars</option>
                                    <option value="Euro">Euro</option>
                                </select>
                            </div>
                        </li> -->
      <!-- <li><a href="https://www.facebook.com/officialcloudkitch/" target="_blank" class="social-list-item"><i class="fab fa-facebook-f facebook fabFooterIcons"></i></a></li>
                        <li><a href="https://www.instagram.com/cloudkitch/" target="_blank" class="social-list-item"><i class="fab fa-instagram instagram fabFooterIcons"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/cloudkitch-private-limited" target="_blank" class="social-list-item"><i class="fab fa-linkedin linkedIn fabFooterIcons"></i></a></li>
                    </ul>
                </div>
                <div class="col-lg-6 align-right rightsReserved">
                    <span class="rights">© 2021 CloudKitch Pvt Ltd. All Rights Reserved.</span> -->
      <!-- <ul class="additional_links">
                        <li><a href="#0">Terms and conditions</a></li>
                        <li><a href="#0">Privacy</a></li>
                        <li><span>© FooYes</span></li>
                    </ul> -->
      <!-- </div>
            </div> -->
    </div>
  </footer>
</template>
<style scoped>
footer {
  padding: 0px !important;
}
.darkBrown * {
  color: #663333 !important;
}
.appStores {
  margin-bottom: 24px;
}
.onlyMobile {
  display: none !important;
}
@media (max-width: 971px) {
  .onlyMobile {
    display: block !important;
  }
  .footer-social-links {
    width: 50% !important;
  }
}
.footer-social-links {
  width: 43%;
  margin: auto;
  margin-bottom: 50px;
}
.footer {
  left: 0;
}
.footerLogo {
  object-fit: contain;
  height: 80px;
}
.aboutCk {
  color: #f3766a;
}
.aboutCk p {
  margin-bottom: 30px;
}
.appStore {
  object-fit: contain;
  margin-left: 12.5px;
}
.playStore {
  object-fit: contain;
  margin-right: 12.5px;
}
.rights {
  color: #212121;
}
.bottomFooter {
  margin: 55px 0 !important;
}
.social-list-item {
  background: #212121;
  border: none;
}
.facebook:hover {
  color: #5b73e8;
}
.instagram:hover {
  color: #825631;
}
.linkedIn:hover {
  color: #0e76a8;
}
.fabFooterIcons {
  color: #fff;
  line-height: 2.2;
}
.vsa-list {
  --vsa-heading-padding: 0 0;
  --vsa-highlight-color: transparent;
  --vsa-bg-color: transparent;
  --vsa-border-color: #eaeaea00;
  --vsa-default-icon-size: 0.3;
}
.vsa-item__trigger {
  padding: 10px !important;
}
</style>
<style>
.vsa-item__trigger[type="button"],
.vsa-item__trigger[type="reset"],
.vsa-item__trigger[type="submit"] {
  height: 20px;
}
.vsa-item__heading,
.vsa-item__trigger {
  color: #663333 !important;
  padding: 10px 0px 10px 5px !important;
}
.vsa-item__trigger__content {
  font-size: 0.8125rem !important;
  font-weight: 100 !important;
}
.vsa-item__content {
  margin: 0;
  padding: 10px;
  font-size: 0.8125rem !important;
}
.vsa-item__content a {
  color: #663333 !important;
}

.footer-selector li {
  float: left;
  margin: 5px !important;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .aboutCk {
    padding-top: 50px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 375px) {
  .footer-social-links {
    width: 36%;
  }
}

@media only screen and (min-width: 426px) and (max-width: 768px) {
  .footer-social-links {
    width: 18%;
  }
}
</style>
